<template lang="pug">
  span
    div
      v-btn(
        v-if="model.result && btnResultLabel"
        @click="resultShowAndMarkSeen"
        small
        outlined
        color="primary"
        data-test="results-show") {{ btnResultLabel }}
    div
      v-tooltip(top max-width="400" :disabled="resultsLoading ? false : true").tooltip
        template(v-slot:activator="{ on }")
          v-btn(
            v-on="on"
            color="primary"
            @click="loadResult"
            small
            data-test="results-get"
            :loading="statusLoaded"
            v-if="canLoadData || statusLoaded || statusFailed"
          ).text-capitalize Get
        span Queued

    v-dialog(v-model="showing" max-width="500" v-if="model.result" scrollable)
      v-card(data-test="results-info-dialog")
        v-card-title.headline {{ model.candidate.name }} results:
        v-divider
        v-card-text
          h3.form-title Main info
          p.text-box
            span.text-box__title Results:
            span.text-box__content {{ model.result.results }}
          p.text-box
            span.text-box__title Categorie:
            span.text-box__content {{ model.productName }}
          h3.form-title Stats
          template(v-if="model.result.non_bth_result !== null")
            p.text-box
              span.text-box__title Resultaten:
              span.text-box__content
                template(v-if="model.result.non_bth_result") {{ model.result.non_bth_result }}
                template(v-else) -
          template(v-else-if="model.result.non_bth_result === null")
            p.text-box
              span.text-box__title Onderdeel 1. Gevaarherkenning:
              span.text-box__content
                template(v-if="model.result.hazard_perception") {{ model.result.hazard_perception }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 2. Kennis:
              span.text-box__content
                template(v-if="model.result.knowledge") {{ model.result.knowledge }}
                template(v-else) -
            p.text-box
              span.text-box__title Onderdeel 3. Inzicht:
              span.text-box__content
                template(v-if="model.result.insight") {{ model.result.insight }}
                template(v-else) -
          h3.form-title(v-if="mistake") Mistake:
          p.text-box(v-if="mistake")
            span.text-box__content(v-html="mistake" )
        v-card-actions
          v-spacer
          v-btn(text color="primary" @click="hideModal" data-test="results-info-dialog-cancel") close
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import resultMixin from '../core/mixins/result.mixin'
import { RESULT_STATUS } from '../core/models/resultReservedExam'
import { state } from "./ResultsFilters.vue"

export default {
  mixins: [errorsMixin, resultMixin],

  props: {
    loadedNotSeen: {
      type: Boolean,
      required: true
    },
    loadedSeen: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    btnResultLabel() {
      if (!this.model.result.results) return null
      return this.model.result.results[0].toUpperCase()
    },

    mistake() {
      if (this.statusInit) return null
      return this.model.result.mistake.replace(/\n/g, '<br />')
    },

    canLoadData() {
      return !!(!this.model.result || (!this.model.is_mijn_reservation && this.model.result.can_be_loaded));
    },

    resultsLoading() {
      return state.resultsLoading
    },

    statusLoaded() {
      return this.model.result && this.model.result.status === RESULT_STATUS.FETCHING
    },

    statusFailed() {
      return this.model.result && this.model.result.status === RESULT_STATUS.FAILED

    }
  },

  methods: {
    async loadResult() {
      try {
        this.model.loading = true
        await this.svc.loadResult(this.model.ID)
      } catch (error) {
        this.model.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.text-box {
  margin-top: 0;
  margin-bottom: 5px;

  &__content {
    color: #000;
  }
}

.btn-result {
  display: inline-block;
}
</style>
